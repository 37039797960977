<template>
  <div class="height_100">
    <el-container class="height_100">
      <el-aside class="left" style="background-color: #2e3751">
        <el-menu
          @open="handleOpen"
          @close="handleClose"
          @select="handleSelect"
          class="el-menu-vertical-demo"
          background-color="#2e3751"
          text-color="#fff"
          active-text-color="#ff6618"
          :default-active="activeIndex"
        >
          <el-menu-item class="menu-item" @click="goHome">
            <span slot="title">网站首页</span>
          </el-menu-item>

          <el-menu-item class="menu-item" @click="goclaim" index="1">
            <i class="el-icon-menu"></i>
            <span slot="title" style="font-size: 18px">服务机构认领</span>
          </el-menu-item>
          <el-menu-item class="menu-item" @click="goproduct" index="2">
            <i class="el-icon-menu"></i>
            <span slot="title" style="font-size: 18px">我的产品</span>
          </el-menu-item>
          <el-menu-item class="menu-item" @click="goorder" index="3">
            <i class="el-icon-menu"></i>
            <span slot="title" style="font-size: 18px">我的订单</span>
          </el-menu-item>
          <!-- <el-menu-item class="menu-item" @click="gorecharge" index="4">
            <span slot="title" style="font-size: 20px">服务币充值</span>
          </el-menu-item> -->
          <!-- <el-menu-item class="menu-item" @click="goserve">
            <span slot="title" style="font-size: 15px">服务报送</span>
          </el-menu-item> -->

          <!-- <el-submenu index="5">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span slot="title" style="font-size: 18px">服务报送</span>
            </template>
            <el-menu-item-group>
              <el-menu-item style="font-size: 14px" index="5-1"
                >基本信息月报</el-menu-item
              >
              <el-menu-item style="font-size: 14px" index="5-2"
                >服务活动月报</el-menu-item
              >
              <el-menu-item style="font-size: 14px" index="5-3"
                >下月服务计划</el-menu-item
              >
              <el-menu-item style="font-size: 14px" index="5-4"
                >服务活动填报表</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu> -->
          <el-menu-item class="menu-item" @click="goservewriting" index="9">
            <i class="el-icon-menu"></i>
            <span slot="title" style="font-size: 18px">服务活动填报表</span>
          </el-menu-item>
          <el-menu-item class="menu-item" @click="goregulator" index="6">
            <i class="el-icon-menu"></i>
            <span slot="title" style="font-size: 18px">诉求管理</span>
          </el-menu-item>
          <el-menu-item class="menu-item" @click="goservemarketr" index="7">
            <i class="el-icon-menu"></i>
            <span slot="title" style="font-size: 18px">供方市场管理</span>
          </el-menu-item>
          <el-menu-item class="menu-item" @click="goserveneed" index="8">
            <i class="el-icon-menu"></i>
            <span slot="title" style="font-size: 18px">需方市场管理</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header
          style="
            height: 72px;
            line-height: 72px;
            border-bottom: 1px solid #e6e6e6;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: #2e3751;
            color: #fff;
            font-size: 20px;
          "
        >
        青海省中小企业公共服务平台-服务机构个人中心

          <div style="display: flex; align-items: center">
            <i
              class="el-icon-user-solid"
              style="color: #fff; font-size: 20px"
            ></i>
            <div style="padding: 0 12px; color: #fff; font-size: 20px">
              {{ userphone }}
              <span style="margin-left: 10px">个人中心</span>
            </div>
            <div @click="login">退出</div>
          </div>
        </el-header>
        <el-main>
          <router-view v-if="this.$route.path != '/personalcenter'" />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>


<script>
export default {
  data() {
    return {
      userphone: localStorage.phone,
      activeIndex: "1",
    };
  },
  created() {
    console.log(this.$route.query.index,'this.$route.query.index')
    if(this.$route.query.index){
      this.goservewriting();
      this.activeIndex = '9'
    }else{
      this.goclaim();
    }
    
  },
  methods: {
    goservewriting(){
      this.$router.push("/serveWriting");
    },
    handleSelect(key, keyPath) {
      console.log(key, "keyhandleSelect");
      console.log(keyPath, "keyPathhandleSelect");
      if (key == "5-1") {
        this.$router.push("/information");
      } else if (key == "5-2") {
        this.$router.push("/serviceAction");
      } else if (key == "5-3") {
        this.$router.push("/nextAction");
      }
      else if (key == "5-4") {
        this.$router.push("/serveWriting");
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath, "handleOpen");
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath, "handleClose");
    },
    goorder() {
      this.$router.push("/order");
    },
    goproduct() {
      this.$router.push("/product");
    },
    // gorecharge() {
    //   this.$router.push("/recharge");
    // },
    goserve() {
      this.$router.push("/serve");
    },
    goserveneed() {
      this.$router.push("/serveneed");
    },
    goregulator() {
      this.$router.push("/regulator");
    },
    goclaim() {
      this.$router.push("/claim");
    },
    goservemarketr() {
      this.$router.push("/servemarket");
    },
    goHome() {
      this.$router.push("/");
    },
    login() {
      this.$confirm("是否退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$get("/logout?token=" + this.token).then((res) => {
            localStorage.clear();
            // location.reload();
            this.$message({
              type: "success",
              message: "已退出",
            });
            this.$router.push("/");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  padding-left: 20px;
  color: rgb(255, 255, 255);
  background: rgb(46, 55, 81);
  text-align: center;
  font-size: 20px;
  height: 70px;
  line-height: 70px;
}
.menu_class {
  height: 60px;
  line-height: 60px;
}
.el-menu {
  border: transparent;
}
.menu-item {
  background: #262e45;
  text-align: left;
  font-size: 20px;
  height: 70px;
  line-height: 70px;
}
// /deep/.el-menu-item.is-active {
//   height: 60px;
//   color: #fff !important;
//   background-color: #ff6618 !important;
// }
::v-deep.el-menu-item.is-active {
  height: 70px;
  color: #fff !important;
  line-height: 70px;
  background-color: #ff6618 !important;
}
::v-deep .el-submenu {
  text-align: left;
}
::v-deep .el-aside {
  width: 250px !important;
}
::v-deep .el-main {
  padding: 30px !important;
}
</style>
